import Slider from '@mui/material/Slider'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserTypes } from 'store/auth/types'
import { roundTo, roundToTwoDecimals } from 'utils/number'
import styles from './EditInstalment.module.scss'
import { FieldTypes, InstalmentForm } from './types'
import debounce from 'lodash/debounce'
import useWindowSize from 'hooks/useWindowSize'
import { useBeforeunload } from 'react-beforeunload'
import { determineAccessRights, isActiveContract } from 'utils/contracts'
import { addNewInstalmentToContract } from './utils'
import { generateBillShockAxiosRequestConfig } from 'api/contracts'
import { newRequest } from 'utils/request'
import { routes } from 'types/routes'
import Bugsnag from '@bugsnag/js'
import { Navigate, useNavigate } from 'react-router'
import { Response } from 'types/request'
import { GetBillShockResponseData } from 'types/billShock'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Button, Icon, TextField } from '@boltenergy-be/design-system'
import EmptyState from 'components/ReturnLater/EmptyState.tsx'
import { ContractStatus, ServiceContractElectricity, ServiceContractGas, AccessRightsKeys } from 'types/contracts.ts'
import { updateInstalment } from 'store/contact/thunks'
import { selectContact, selectCurrentContracts } from 'store/contact/selectors.ts'
import { useGetBillShockQuery } from 'store/queries/bolt-api/contracts'
import EditLayout from 'layouts/edit-layout/EditLayout.tsx'
import Card from 'components/Card/Card.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { formatCurrency } from 'utils/format.ts'
import { Language } from 'store/app/types.ts'
import mixpanel from 'mixpanel-browser'
import { BillingEvents } from 'types/tracking.ts'
import EstimatedSettlement from 'pages/App/billing/billing-cycles/components/estimated-settlement/EstimatedSettlement.tsx'

const EditInstalment = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { selected, billingContracts, contact, loading } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Contracts
  const { email } = selectContact({ contact })
  const billingContract = selectCurrentContracts({ selected, billingContracts })
  const { electricity, gas } = billingContract.serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // Redux queries
  const { data: billShockData, isLoading: billShockLoading } = useGetBillShockQuery(
    {
      billingContractId: selected.billingContract,
      email,
      serviceContracts: billingContract.serviceContracts
    },
    {
      skip:
        !selected.billingContract ||
        !accessRights[AccessRightsKeys.BILLING_CYCLES].canAccess ||
        !accessRights[AccessRightsKeys.BILLING_CYCLES].showContent
    }
  )

  // i18n
  const { t } = useTranslation(['billing', 'validation', 'common'])

  // Constants
  const billShock = billShockData?.billShock
  const electricityContractStarted = isActiveContract(electricity)
  const currentElectricityInstalment = electricityContractStarted ? Number(electricity.detail.instalment || 0) : 0
  const currentGasInstalment = gas && isActiveContract(gas) ? Number(gas?.detail.instalment || 0) : 0
  const currentInstalment = roundToTwoDecimals(currentElectricityInstalment + currentGasInstalment)
  const hasEffectiveGasContract = typeof gas !== 'undefined' && gas.detail.status === ContractStatus.EFFECTIVE
  const prefixOrSuffix = language === Language.NL ? { prefix: { as: '€' } } : { suffix: { as: '€' } }

  // Local state
  const [billShockAmount, setBillShockAmount] = useState<number | null>(null)
  const [electricityRatio, setElectricityRatio] = useState<number>(0)
  const [gasRatio, setGasRatio] = useState<number>(0)
  const [hoveringOnMark, setHoveringOnMark] = useState<boolean>(false)
  const [instalmentSaved, setInstalmentSaved] = useState<boolean>(false)
  const [maxInstalment, setMaxInstalment] = useState<number>(0)
  const [minInstalment, setMinInstalment] = useState<number>(0)
  const [proposedInstalment, setProposedInstalment] = useState<number>()
  const [refetchingBillShock, setRefetchingBillShock] = useState<boolean>(false)

  // Window size
  const { isMobile } = useWindowSize()

  // Router
  const navigate = useNavigate()

  // React hook form
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm<InstalmentForm>({
    mode: 'onChange',
    defaultValues: {
      totalInstalment: Number(currentInstalment),
      electricityInstalment: Number(currentElectricityInstalment),
      gasInstalment: Number(currentGasInstalment)
    },
    shouldFocusError: false
  })

  // React Hook Form Watch values
  const watchElectricityInstalment = watch('electricityInstalment')
  const watchGasInstalment = watch('gasInstalment')
  const watchTotalInstalment = watch('totalInstalment')

  /**
   * Calculate instalment data when bill shock stopped loading
   */
  useEffect(() => {
    if (!billShockLoading) calculateInstalmentData()
  }, [billShockLoading])

  // Shows dialog when user tries to reload the page without confirming
  useBeforeunload((e: any) => {
    if (currentInstalment !== watchTotalInstalment && !instalmentSaved) {
      e.preventDefault()
    }
  })

  /**
   * Initializes the instalment page data fetch & sets the correct values in the store
   */
  const calculateInstalmentData = async () => {
    let elRatio = 0.5
    let ngRatio = 0.5
    let minimumInstalment = 20

    if (billShock) {
      const { instalment, settlement } = billShock

      // Ratio's
      elRatio = instalment.estimatedRatio.electricity
      ngRatio = instalment.estimatedRatio.gas

      // Set the min instalment (if not super user)
      if (userType !== UserTypes.SUPER_USER) {
        minimumInstalment = Math.max(roundTo(5, instalment.minimum), 20)
      }
      setMinInstalment(minimumInstalment)

      // Set the max instalment
      const proposedInstalmentTimesTwo = instalment.proposed > 0 ? roundTo(50, instalment.proposed * 1.5) : 20
      const maxInstalmentFromProposed =
        proposedInstalmentTimesTwo < 500 ? (instalment.current > 500 ? instalment.current * 1.5 : 500) : proposedInstalmentTimesTwo
      if (maxInstalment === 0) {
        setMaxInstalment(
          Math.round(
            instalment.proposed > 0 && instalment.proposed < currentInstalment
              ? currentInstalment * 1.5
              : roundTo(25, maxInstalmentFromProposed)
          )
        )
      }

      // Set billshock amount & proposed instalment (if higher than current instalment)
      setBillShockAmount(settlement.billShockAmount)
      if (instalment.proposed > currentInstalment) {
        setProposedInstalment(instalment.proposed)
      }
    } else {
      // NO BILLSHOCK DATA: no ratio's, no min/max, no proposed instalment, no billshock amount
      // elRatio = ratio calculated based on currentInstalment EXCEPT when currentInstalment is 0, than 0.5
      // ngRatio = ratio calculated based on currentInstalment EXCEPT when currentInstalment is 0, than 0.5
      // min = currentInstalment * 0.8 EXCEPT when superuser or when currentInstalment is 0 (= not returned from API), than 20
      // max = currentInstalment * 2 EXCEPT if smaller than 500, than 500
      // proposedInstalment & billshockAmount: not shown

      // Ratio's
      if (currentInstalment !== 0) {
        elRatio = currentElectricityInstalment / currentInstalment
        ngRatio = currentGasInstalment / currentInstalment
      }
      // If only gas, set elRatio to 1
      if (!hasEffectiveGasContract) {
        elRatio = 1
        ngRatio = 0 // fallback
      }

      // Set the min instalment if not super user
      if (userType !== UserTypes.SUPER_USER && currentInstalment !== 0) minimumInstalment = roundTo(5, currentInstalment * 0.8)

      // Set the max instalment
      const currentInstalmentTimesTwo = currentInstalment * 1.5
      if (maxInstalment === 0) {
        setMaxInstalment(roundTo(50, Math.round(currentInstalmentTimesTwo < 500 ? 500 : currentInstalmentTimesTwo)))
      }
    }

    setElectricityRatio(elRatio)
    setGasRatio(ngRatio)
    if (minInstalment === 0) {
      setMinInstalment(minimumInstalment)
    }
  }

  /**
   * Handles the submit after validation by RHF
   * TODO: refactor to redux query mutation with invalidation on billshock query
   *
   * @param data
   */
  const onSubmit = async (data: InstalmentForm) => {
    const { electricityInstalment, gasInstalment } = data

    const updatedElectricityContract = addNewInstalmentToContract(electricity, electricityInstalment)

    let updatedGasContract
    if (hasEffectiveGasContract && gasInstalment) {
      updatedGasContract = addNewInstalmentToContract(gas, gasInstalment)
    }

    setInstalmentSaved(true)

    // Save the contracts
    await dispatch(
      updateInstalment({
        billingContractId: billingContract.contractNumber,
        customerNumber: billingContract.customerNumber,
        electricity: updatedElectricityContract,
        gas: updatedGasContract
      })
    )

    mixpanel.track(BillingEvents.CONFIRM_CHANGE_INSTALMENT)

    navigate(-1)
  }

  /**
   * Re-fetches bill shock data
   * TODO: check if this can be combined with the redux query for billshock
   */
  const refetchBillShock = async (updatedElectricityContract: ServiceContractElectricity, updatedGasContract?: ServiceContractGas) => {
    setRefetchingBillShock(true)

    const options = generateBillShockAxiosRequestConfig(
      billingContract.contractNumber,
      email,
      updatedElectricityContract,
      updatedGasContract
    )
    const { success, data }: Response<GetBillShockResponseData> = await newRequest(options)

    if (success && data) {
      if (data.billShock) {
        setBillShockAmount(data.billShock.settlement.billShockAmount)

        if (data.billShock.instalment.proposed > currentInstalment) {
          setProposedInstalment(data.billShock.instalment.proposed)
        }
      }
    } else {
      Bugsnag.notify('Error while fetching bill shock data or empty response')
    }

    setRefetchingBillShock(false)
  }

  const refetchBillShockDebounce = useRef(debounce(refetchBillShock, 500, { leading: false, trailing: true }))

  /**
   * Handles the instalment change event (both total & fuel fields)
   *
   * @param {number} [newTotalInstalment]
   * @param {FieldTypes} fieldType
   */
  const handleInstalmentChange = (fieldType: FieldTypes, newTotalInstalment: number) => {
    setInstalmentSaved(false)

    const totalInstalment = newTotalInstalment || 0

    let electricityInstalment = watchElectricityInstalment
    let gasInstalment = watchGasInstalment

    // The field that was changed was the "totalInstalment" field
    if (fieldType === FieldTypes.TOTAL) {
      if (newTotalInstalment) {
        electricityInstalment = electricityRatio === 0 ? 10 : roundToTwoDecimals(totalInstalment * electricityRatio)
        gasInstalment = electricityRatio === 0 ? totalInstalment - 10 : roundToTwoDecimals(totalInstalment * gasRatio)
      }

      setValue('totalInstalment', totalInstalment, { shouldValidate: true })
      setValue('electricityInstalment', electricityInstalment, { shouldValidate: true })
      setValue('gasInstalment', gasInstalment, { shouldValidate: true })
    }

    // The field that was changed was the "electricityInstalment" or "gasInstalment" field
    if (fieldType === FieldTypes.FUEL) {
      setValue('totalInstalment', roundToTwoDecimals(newTotalInstalment || 0), { shouldValidate: true })
    }

    // Refetch billShock (if it was available during pageload)
    if (billShockAmount !== null) {
      const updatedElectricityContract = addNewInstalmentToContract(electricity, electricityInstalment)

      let updatedGasContract
      if (hasEffectiveGasContract && gasInstalment) {
        updatedGasContract = addNewInstalmentToContract(gas, gasInstalment)
      }

      refetchBillShockDebounce.current(updatedElectricityContract, updatedGasContract)
    }
  }

  // Slider marks
  const marks = []
  if (proposedInstalment) {
    marks.push({
      value: proposedInstalment,
      label: formatCurrency(proposedInstalment, { language })
    })
  }

  // Custom component for mark on slider
  const CustomMark = ({ children, style, className }: any) => {
    return (
      <button
        className={classNames(className, styles['mark-proposed-instalment'], {
          [styles.hover]: hoveringOnMark
        })}
        style={style}
        onMouseDown={(e) => {
          e.preventDefault()
          handleInstalmentChange(FieldTypes.TOTAL, Number(proposedInstalment))
        }}
        onMouseEnter={() => setHoveringOnMark(true)}
        onMouseLeave={() => setHoveringOnMark(false)}
      >
        {children}
      </button>
    )
  }

  // Custom component for mark label on slider
  const CustomMarkLabel = ({ children, style, className }: any) => {
    return (
      <button
        className={classNames(className, styles['mark-label-proposed-instalment'], {
          [styles.hover]: hoveringOnMark,
          [styles['first-third']]: typeof proposedInstalment === 'number' && (proposedInstalment || 0) <= maxInstalment / 3,
          [styles['last-third']]: typeof proposedInstalment === 'number' && (proposedInstalment || 0) >= (maxInstalment / 3) * 2
        })}
        style={style}
        onMouseDown={(e) => {
          e.preventDefault()
          handleInstalmentChange(FieldTypes.TOTAL, Number(proposedInstalment))
        }}
        onMouseEnter={() => setHoveringOnMark(true)}
        onMouseLeave={() => setHoveringOnMark(false)}
      >
        <div className={styles.positioner}>
          <Icon name="arrowTop" className={styles.arrow} />
          <div className={styles.proposed}>
            {t('editInstalment.proposedInstalment')}: {children}
          </div>
        </div>
      </button>
    )
  }

  return !accessRights.instalment.canAccess ? (
    <Navigate to={routes.BILLING_INVOICES} replace />
  ) : !accessRights.instalment.showContent ? (
    <EmptyState description={t('editInstalment.returnLater')} />
  ) : (
    <EditLayout title={t('editInstalment.title')} classes={{ body: styles.body }}>
      <p>{t('editInstalment.description')}</p>

      {accessRights.billingCycles.canAccess && accessRights.billingCycles.showContent && billShockLoading ? (
        <LoadingSkeleton>
          <LoadingSkeleton.Rectangle height={10} className="mt-300" />

          <LoadingSkeleton.Rectangle aspectRatio="2 / 1" className="mt-600" />
        </LoadingSkeleton>
      ) : (
        <>
          <div className={styles['slider-wrapper']}>
            <div className={styles['slider-container']}>
              <Slider
                className={styles['instalment-slider']}
                min={minInstalment}
                max={maxInstalment}
                step={5}
                marks={marks}
                onChange={(_, value) => handleInstalmentChange(FieldTypes.TOTAL, Number(value))}
                value={watchTotalInstalment}
                components={{
                  // TODO: refactor to slots or custom component. components prop is deprecated
                  Mark: CustomMark,
                  MarkLabel: CustomMarkLabel
                }}
              />
              <small className={classNames(styles['mark-label'], styles['mark-label-min'])}>
                {formatCurrency(minInstalment, { language })}
              </small>
              <small className={classNames(styles['mark-label'], styles['mark-label-max'])}>
                {formatCurrency(maxInstalment, { language })}
              </small>
            </div>
          </div>

          <form className={styles['instalment-form']} onSubmit={handleSubmit(onSubmit)}>
            <Card border={false} className={styles['edit-card']}>
              <TextField
                {...register('totalInstalment', {
                  required: t('validation:required'),
                  min: {
                    value: minInstalment,
                    message: t('editInstalment.form.errors.min', { amount: formatCurrency(minInstalment, { language }) })
                  },
                  max:
                    userType !== UserTypes.SUPER_USER
                      ? {
                          value: maxInstalment,
                          message: t('editInstalment.form.errors.max', { amount: formatCurrency(maxInstalment, { language }) })
                        }
                      : undefined,
                  validate: {
                    ...(userType === UserTypes.CUSTOMER && {
                      minElectricityInstalment: () =>
                        watchElectricityInstalment !== 0 || t('editInstalment.form.errors.minElectricityInstalment')
                    }),
                    ...(hasEffectiveGasContract &&
                      userType === UserTypes.CUSTOMER &&
                      typeof watchGasInstalment !== 'undefined' && {
                        minGasInstalment: () => watchGasInstalment !== 0 || t('editInstalment.form.errors.minGasInstalment')
                      })
                  }
                })}
                error={errors?.totalInstalment?.message || !!errors?.totalInstalment?.type}
                label={t('editInstalment.newInstalment')}
                type="number"
                step="0.01"
                onChange={(e) => {
                  setValue('totalInstalment', roundToTwoDecimals(Number(e.target.value)))
                  handleInstalmentChange(FieldTypes.TOTAL, Number(e.target.value))
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(onSubmit)()
                  }
                }}
                onWheel={(e) => e.currentTarget.blur()}
                {...prefixOrSuffix}
              />

              {hasEffectiveGasContract && (
                <>
                  <TextField
                    {...register('electricityInstalment', {
                      min: {
                        value: 1,
                        message: t('editInstalment.form.errors.minElectricityInstalment')
                      },
                      required: t('validation:required'),
                      valueAsNumber: true
                    })}
                    onChange={(e) => {
                      const value = roundToTwoDecimals(Number(e.currentTarget.value))
                      setValue('electricityInstalment', value, { shouldValidate: true })
                      handleInstalmentChange(FieldTypes.FUEL, value + (watchGasInstalment || 0))
                    }}
                    onWheel={(e) => e.currentTarget.blur()}
                    error={errors?.electricityInstalment?.message || !!errors?.electricityInstalment?.type}
                    label={t('common:electricity')}
                    type="number"
                    step="0.01"
                    {...prefixOrSuffix}
                  />

                  <TextField
                    {...register('gasInstalment', {
                      min: {
                        value: 1,
                        message: t('editInstalment.form.errors.minGasInstalment')
                      },
                      required: t('validation:required'),
                      valueAsNumber: true
                    })}
                    onChange={(e) => {
                      const value = roundToTwoDecimals(Number(e.target.value))
                      setValue('gasInstalment', value, { shouldValidate: true })
                      handleInstalmentChange(FieldTypes.FUEL, value + (watchElectricityInstalment || 0))
                    }}
                    onWheel={(e) => e.currentTarget.blur()}
                    error={errors?.gasInstalment?.message || !!errors?.gasInstalment?.type}
                    label={t('common:gas')}
                    type="number"
                    step="0.01"
                    {...prefixOrSuffix}
                  />
                </>
              )}

              {billShockAmount !== null && <EstimatedSettlement settlementAmount={billShockAmount} loading={refetchingBillShock} />}
            </Card>

            <Button
              type="submit"
              isFullwidth
              disabled={!isValid || currentInstalment === watchTotalInstalment || instalmentSaved}
              loading={loading}
            >
              {isMobile
                ? t('editInstalment.form.buttonMobile', {
                    newInstalment: formatCurrency(watchTotalInstalment, { language })
                  })
                : t('editInstalment.form.button', {
                    newInstalment: formatCurrency(watchTotalInstalment, { language })
                  })}
            </Button>
          </form>
        </>
      )}
    </EditLayout>
  )
}

export default EditInstalment
