import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { ChartData } from 'chart.js'
import CurrentBillingCycleChartMobile from './ChartMobile'
import CurrentBillingCycleChartDesktop from './ChartDesktop'
import { updateProposedInstalmentBillShockData } from '../utils'
import { CurrentBillingCycleChartWrapperProps } from './types'
import { BillingCycleChartProps } from '../types.ts'
import { MinMaxData } from '../../types'
import { getBillingCycleMinMaxRange } from 'utils/chartjs'
import { CycleViews } from 'pages/App/billing/billing-cycles/types'
import { getBillingCycleChartDatasets } from './chartjs.config'
import { BillShock } from 'types/billShock'
import { useTranslation } from 'react-i18next'
import Totals from 'pages/App/consumption/your-consumption/components/totals-block/TotalsBlock.tsx'
import { DSChartColors } from 'types/colors.ts'
import { formatAmount } from 'utils/format.ts'

const CurrentBillingCycleChart = ({ data, isTablet, activePeriod, adjustedBillShockAmount }: CurrentBillingCycleChartWrapperProps) => {
  // i18n
  const { t } = useTranslation('billing')

  // Local state
  const [activeDataPointIndex, setActiveDataPointIndex] = useState<number | null>(null)

  // Constants
  const months = useMemo<string[]>(
    () =>
      data
        ? [
            ...data.past.map((instalment) => dayjs(instalment.datetime).toISOString()),
            ...data.future.map((instalment) => dayjs(instalment.datetime).toISOString()),
            t('billingCycle.chart.settlement')
          ]
        : [],
    [data, t]
  )

  const transformedData = useMemo<BillShock>(() => updateProposedInstalmentBillShockData(data), [data])
  const minMaxData = useMemo<MinMaxData>(() => getBillingCycleMinMaxRange(transformedData), [transformedData])
  const settlement = activePeriod === CycleViews.CURRENT ? transformedData.settlement.billShockAmount : adjustedBillShockAmount || 0
  const settlementColor = settlement <= 0 ? DSChartColors.GREEN : DSChartColors.ORANGE
  const combinedDataArray = [
    ...transformedData.past.map(({ billedInstalment }) => ({
      value: billedInstalment,
      alreadyBilled: true
    })),
    ...transformedData.future.map(({ plannedInstalment, proposedInstalment }) => ({
      value: activePeriod === CycleViews.CURRENT ? plannedInstalment : proposedInstalment,
      alreadyBilled: false
    })),
    { value: activePeriod === CycleViews.CURRENT ? transformedData.settlement.billShockAmount : 20, alreadyBilled: null }
  ]
  const totalBilled = transformedData.past.reduce((acc, { billedInstalment }) => acc + billedInstalment, 0)
  const totalFuture = transformedData.future.reduce(
    (acc, { plannedInstalment, proposedInstalment }) =>
      acc + (activePeriod === CycleViews.CURRENT ? plannedInstalment : proposedInstalment),
    0
  )
  const isLastDataPoint = activeDataPointIndex === combinedDataArray.length - 1

  // Table data
  const chartData = useMemo<ChartData<'bar', (number | null)[]>>(
    () => ({
      labels: months,
      datasets: getBillingCycleChartDatasets(months, transformedData, activePeriod, isTablet, settlement)
    }),
    [months, transformedData, activePeriod, isTablet, settlement]
  )

  const chartProps: BillingCycleChartProps = {
    chartData,
    minMaxData,
    labels: months,
    setHoverDataPointIndex: setActiveDataPointIndex
  }

  return (
    <>
      <Totals listOnMobile>
        {/* BILLED */}
        <Totals.Block
          label={t('billingCycle.chart.billedInstalment')}
          indicator={{ color: DSChartColors.PINK, full: true }}
          addendum={typeof activeDataPointIndex === 'number' ? undefined : t('billingCycle.chart.total')}
        >
          {isLastDataPoint ? null : typeof activeDataPointIndex === 'number' && !!combinedDataArray[activeDataPointIndex] ? (
            combinedDataArray[activeDataPointIndex].alreadyBilled && (
              <data value={combinedDataArray[activeDataPointIndex].value}>
                € {formatAmount(combinedDataArray[activeDataPointIndex].value)}
              </data>
            )
          ) : (
            <data value={totalBilled}>€ {formatAmount(totalBilled)}</data>
          )}
        </Totals.Block>

        <Totals.Block
          label={t('billingCycle.chart.futureInstalment')}
          indicator={{
            color: activePeriod === CycleViews.CURRENT ? DSChartColors.PINK : DSChartColors.LIGHTBLUE,
            stripped: true
          }}
          addendum={typeof activeDataPointIndex === 'number' ? undefined : t('billingCycle.chart.total')}
        >
          {isLastDataPoint ? null : typeof activeDataPointIndex === 'number' && !!combinedDataArray[activeDataPointIndex] ? (
            !combinedDataArray[activeDataPointIndex].alreadyBilled && (
              <data value={combinedDataArray[activeDataPointIndex].value}>
                € {formatAmount(combinedDataArray[activeDataPointIndex].value)}
              </data>
            )
          ) : (
            <data value={totalFuture}>€ {formatAmount(totalFuture)}</data>
          )}
        </Totals.Block>

        <Totals.Block label={t('billingCycle.chart.estimatedSettlement')} indicator={{ color: settlementColor, full: true }}>
          {(typeof activeDataPointIndex !== 'number' || isLastDataPoint) && <data value={settlement}>€ {formatAmount(settlement)}</data>}
        </Totals.Block>
      </Totals>

      {isTablet ? <CurrentBillingCycleChartMobile {...chartProps} /> : <CurrentBillingCycleChartDesktop {...chartProps} />}
    </>
  )
}

export default CurrentBillingCycleChart
