import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { selectContact, selectCurrentContracts } from 'store/contact/selectors.ts'
import { determineAccessRights, isCloseToTermination } from 'utils/contracts.ts'
import { useGetBillShockQuery, useGetPreviousBillingCycleQuery } from 'store/queries/bolt-api/contracts'
import { AccessRightsKeys } from 'types/contracts.ts'
import UsageCard from './components/usage-card/UsageCard.tsx'
import { Banner, Heading as DSHeading, Toggle } from '@boltenergy-be/design-system'
import styles from './BillingCycleDetail.module.scss'
import CurrentBillingCycleChart from 'components/Charts/billing-cycle/current/CurrentBillingCycleChart.tsx'
import { CycleViews } from '../billing-cycles/types.ts'
import parse from 'html-react-parser'
import { formatAmount } from 'utils/format.ts'
import { useState } from 'react'
import Cookies from 'js-cookie'
import { NEVER_SHOW_INSTALMENT_INFO_MODAL } from 'pages/App/billing/billing-cycles/constants.ts'
import InstalmentInfoModal from '../billing-cycles/components/instalment-info-modal/InstalmentInfoModal.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import Card from 'components/Card/Card.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import BillshockError from '../billing-cycles/components/billshock-error/BillshockError.tsx'
import PreviousBillingCycleChart from 'components/Charts/billing-cycle/previous/PreviousBillingCycleChart.tsx'
import Heading from './components/heading/Heading.tsx'
import OverviewCard from '../billing-cycles/components/overview-card/OverviewCard.tsx'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'constants/constants.ts'
import classNames from 'classnames'
import { routes } from 'types/routes.ts'
import Link from 'components/Link/Link.tsx'
import mixpanel from 'mixpanel-browser'
import { BillingEvents } from 'types/tracking.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'

const BillingCycleDetail = () => {
  // URL Param
  const { id } = useParams<{ id: string }>()

  // i18n
  const { t } = useTranslation('billing')

  // Window size
  const { isTablet } = useWindowSize()

  // REDUX STORE
  const { selected, billingContracts, contact } = useStoreSelector((store) => store.contact)

  // Contracts
  const { email } = selectContact({ contact })
  const { serviceContracts } = selectCurrentContracts({ selected, billingContracts })
  const { electricity, gas } = serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)
  const shouldShowMeterReadings = accessRights.meterReadings.canAccess && accessRights.meterReadings.showContent

  const inaccesible =
    !selected.billingContract ||
    !accessRights[AccessRightsKeys.BILLING_CYCLES].canAccess ||
    !accessRights[AccessRightsKeys.BILLING_CYCLES].showContent

  // Redux queries
  const billShockRes = useGetBillShockQuery(
    {
      billingContractId: selected.billingContract,
      email,
      serviceContracts
    },
    { skip: id !== 'current' || inaccesible }
  )
  const previousCycleRes = useGetPreviousBillingCycleQuery(
    {
      billingContractId: selected.billingContract,
      billingPeriodId: id!
    },
    { skip: !id || id === 'current' || inaccesible }
  )

  // Local state
  const [selectedCycleView, setSelectedCycleView] = useState<CycleViews>(CycleViews.CURRENT)
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
  const [hasOpenedInfoModal, setHasOpenedInfoModal] = useState<boolean>(!!Cookies.get(NEVER_SHOW_INSTALMENT_INFO_MODAL))

  // Constants
  const { isLoading, error } = id === 'current' ? billShockRes : previousCycleRes
  const previousData = previousCycleRes.data || undefined
  const currentData =
    selectedCycleView === CycleViews.CURRENT
      ? billShockRes.data?.billShock
      : billShockRes.data?.adjustedBillShock || billShockRes.data?.billShock

  // Current billing cycle constants
  const hasAdjustedBillShock = !!billShockRes?.data?.adjustedBillShock
  const currentInstalment = billShockRes?.data?.billShock?.instalment?.current || 0
  const proposedInstalment = hasAdjustedBillShock ? 20 : billShockRes?.data?.billShock?.instalment?.proposed || 0
  const adjustedBillShockAmount = hasAdjustedBillShock ? billShockRes?.data?.adjustedBillShock?.settlement.billShockAmount : undefined
  const settlement = selectedCycleView === CycleViews.CURRENT ? currentData?.settlement.billShockAmount : adjustedBillShockAmount || 0
  const closeToTermination = isCloseToTermination(electricity)

  return (
    <PageLayout
      backButtonUrl={routes.BILLING_CYCLES}
      seoTitle={t(`billingCycles.${id === 'current' ? 'current' : 'previous'}.title`)}
      title={
        <Heading
          previousBillingCycle={previousData}
          currentBillingCycle={currentData}
          {...{ isLoading }}
          electricityContract={electricity}
        />
      }
      classes={{ body: styles.layout, header: styles.header }}
    >
      {/* USAGE CARD*/}
      <UsageCard
        title={t(`billingCycle.usage.title.${id === 'current' ? 'current' : 'previous'}`)}
        electricity={
          id === 'current'
            ? currentData?.meters.electricity?.totalConsumption || 0
            : previousData?.fuel?.electricity
              ? previousData?.usage.electricity?.consumption
              : undefined
        }
        injection={
          (id === 'current' ? currentData?.meters.electricity?.totalInjection : previousData?.usage.electricity?.injection) || undefined
        }
        gas={
          id === 'current'
            ? gas
              ? currentData?.meters.gas?.totalConsumption || 0
              : undefined
            : previousData?.fuel?.gas
              ? previousData?.usage.gas
              : undefined
        }
        canAddMeterReadings={id === 'current' && shouldShowMeterReadings}
        {...{ isLoading }}
      />

      {/* CHART */}
      <Card as="section" className={styles['chart-card']}>
        {isLoading ? (
          <LoadingSkeleton>
            <LoadingSkeleton.Rectangle height={56} />
            <LoadingSkeleton.Rectangle height={100} />
            <LoadingSkeleton.Rectangle aspectRatio={isTablet ? '1.5 / 1' : '2 / 1'} />
          </LoadingSkeleton>
        ) : error ? (
          <BillshockError error={error} />
        ) : id === 'current' ? (
          closeToTermination ? (
            <Banner type="informative">{t('closeToTermination', { ns: 'common' })}</Banner>
          ) : (
            <>
              <DSHeading as="h1" variant="h6" weight={600}>
                {t('billingCycle.chart.estimatedSettlement')}
              </DSHeading>

              {/* CURRENT BILLING CYCLE */}
              <div className={styles['comparison-toggle-container']}>
                <Toggle
                  isFullwidth
                  active={selectedCycleView}
                  onClick={(view) => {
                    if (!hasOpenedInfoModal) {
                      setOpenInfoModal(true)
                    }
                    setSelectedCycleView(view)
                    mixpanel.track(BillingEvents.TOGGLE_BILLING_CYCLE_VIEW, { view })
                  }}
                  options={[
                    {
                      value: CycleViews.CURRENT,
                      label: parse(t('billingCycle.chart.toggle.current', { instalment: formatAmount(currentInstalment) }))
                    },
                    {
                      value: CycleViews.PROPOSED,
                      label: parse(t('billingCycle.chart.toggle.proposed', { instalment: formatAmount(proposedInstalment) }))
                    }
                  ]}
                />
              </div>

              {currentData && (
                <CurrentBillingCycleChart
                  data={currentData}
                  isTablet={isTablet}
                  activePeriod={selectedCycleView}
                  adjustedBillShockAmount={adjustedBillShockAmount}
                />
              )}
            </>
          )
        ) : (
          previousData && <PreviousBillingCycleChart data={previousData} {...{ isTablet }} />
        )}

        {/*<Button
          className={styles['consumption-button']}
          onClick={() =>
            navigate({
              pathname: routes.CONSUMPTION_ELECTRICITY, // TODO: determine correct page (gas/el) based on active view
              search: `cycle=${period.id}&type=${getPeriodType(data[period.id])}`
            })
          }
        >
          {t('billingCycle.chart.checkPeriodConsumptionBtn')}
        </Button>*/}

        {accessRights[AccessRightsKeys.INSTALMENT].canAccess && id === 'current' && (
          <Link
            representation="button"
            loading={isLoading}
            href={routes.BILLING_EDIT_INSTALMENT}
            isFullwidth
            onClick={() => mixpanel.track(BillingEvents.GO_TO_CHANGE_INSTALMENT, { from: routes.BILLING_CYCLE_DETAIL })}
          >
            {t('billingCycle.overview.editInstalment')}
          </Link>
        )}
      </Card>

      {id === 'current' && (
        <OverviewCard
          isLoading={isLoading}
          date={dayjs(currentData?.computeDate).format(DATE_FORMAT)}
          blocks={[
            {
              label: t('billingCycle.overview.totalBilledInstalments'),
              rawValue: currentData?.past.reduce((sum, instalment) => {
                return sum + instalment.billedInstalment
              }, 0),
              formattedValue:
                currentData &&
                `€
                            ${formatAmount(
                              currentData?.past.reduce((sum, instalment) => {
                                return sum + instalment.billedInstalment
                              }, 0)
                            )}
                          `
            },
            {
              label: t('billingCycle.overview.totalRemainingInstalments'),
              rawValue: currentData?.future.reduce((sum, instalment) => {
                return sum + (selectedCycleView === CycleViews.CURRENT ? instalment.plannedInstalment : instalment.proposedInstalment)
              }, 0),
              formattedValue:
                currentData &&
                `€ ${formatAmount(
                  currentData?.future.reduce((sum, instalment) => {
                    return sum + (selectedCycleView === CycleViews.CURRENT ? instalment.plannedInstalment : instalment.proposedInstalment)
                  }, 0)
                )}`
            },
            {
              label: t('billingCycle.overview.totalCost'),
              rawValue: currentData?.settlement.estimatedTotalAmount,
              formattedValue: currentData && `€ ${formatAmount(currentData?.settlement.estimatedTotalAmount)}`
            },
            {
              label: t('settlement.label'),
              valueClass: classNames(styles['instalment-value'], {
                [styles.danger]: settlement && settlement > 0 && selectedCycleView === CycleViews.CURRENT
              }),
              rawValue: settlement,
              formattedValue:
                typeof settlement === 'number'
                  ? `€ ${formatAmount(Math.abs(settlement))} ${settlement > 0 ? ` ${t('settlement.extra')}` : ` ${t('settlement.recover')}`}`
                  : '—'
            },
            {
              label:
                selectedCycleView === CycleViews.CURRENT
                  ? t('billingCycle.overview.instalment.current')
                  : t('billingCycle.overview.instalment.proposed'),
              rawValue: selectedCycleView === CycleViews.CURRENT ? currentInstalment : proposedInstalment,
              formattedValue:
                selectedCycleView === CycleViews.CURRENT ? `€ ${formatAmount(currentInstalment)}` : `€ ${formatAmount(proposedInstalment)}`
            }
          ]}
        />
      )}

      <InstalmentInfoModal
        isOpen={openInfoModal}
        setClose={() => {
          setOpenInfoModal(false)
          setHasOpenedInfoModal(true)
        }}
      />
    </PageLayout>
  )
}

export default BillingCycleDetail
